import { useState } from "react";
import ReactPlayer from "react-player";
import { PopupButton } from "react-calendly";
import {
  Section,
  TextContent,
  IntroVideo,
  Wrapper,
  ImagePreview,
  ImageBox,
  Image
} from "./freeInitialSupport.style";
import VideoBg from "assets/images/video-background.svg";
import PlayIcon from "assets/images/play-outline-filled.svg";
import PlaceholderImage from "assets/images/placeholder.jpg";
import * as CONST from "constants";

const FreeInitialSupport = ({ coach, baseUrl, onLeadModal }) => {
  const [IsPlay, setIsPlay] = useState(false);
  console.log('base : ', CONST.IMG_BASE_URL)
  console.log('path : ', coach.cw_lp_picture_1)
  const previewImage = coach?.cw_lp_picture_1
    ? `${CONST.IMG_BASE_URL}${encodeURIComponent(coach.cw_lp_picture_1)}`
    : null;

  const previewContent =
    coach?.pp_Intoduction_video_profilpage
    ? (
      <IntroVideo>
        <div className="video-wrapper">
          <div className="video">
            <ReactPlayer
              controls={false}
              url={`${CONST.IMG_BASE_URL}${coach?.pp_Intoduction_video_profilpage}`}
              playing={IsPlay}
              onEnded={() => setIsPlay(false)}
              className='react-player'
              width={'100%'}
              height={'100%'}
            />
            {!IsPlay && (
              <img
                className="play-btn cursor-pointer"
                src={PlayIcon}
                alt="play"
                onClick={() => setIsPlay(true)}
              />
            )}
          </div>
        </div>
      </IntroVideo>
    ) : (
      <ImagePreview>
        <ImageBox>
          <Image image={previewImage || PlaceholderImage} />
        </ImageBox>
      </ImagePreview>
    )

  return (
    <div className="container-smaller">
      <Section>
        <Wrapper>
          <TextContent>
            <h3 className="ct-headline foodiary-h2 color-dark text-4xl mt-8 mb-12">
              <strong>
                Ich unterstütze dich mit meinem <br /> kostenlosen Erstgespräch 🙌
              </strong>
            </h3>
            <p className="ct-text-block color-paragraph font-normal text-base mb-12">
              {coach?.pp_Intoduction_text_profilpage}
            </p>
            <br />

            <button
              className="ct-link-text foodiary-green-button-outline text-decoration-none"
              onClick={() => onLeadModal()}
            >
              Erstgespräch vereinbaren
            </button>
          </TextContent>
          {previewContent}
        </Wrapper>
      </Section>
    </div>
  );
};

export default FreeInitialSupport;
