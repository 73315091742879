import { Routes, Route } from "react-router-dom"
import Home from "pages/Home"
import CoachDetails from "pages/CoachDetails"


function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/coach/:slug" element={<CoachDetails />} />
      </Routes>
    </div>
  )
}

export default App
