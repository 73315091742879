import * as Styles from './CoachInitialSection.styled'
import LeadsSurveyForm from "../../modals/LeadSurveyModal/LeadsSurvey/LeadsSurveyForm";

function CoachInitialSection ({ coachProfile }) {
  return (
    <Styles.SectionContainer>
      <div className="container">
        <div className="container-smaller">
          <Styles.Wrapper>
            <div className='left-side'>
              <h3 className="ct-headline foodiary-h2 color-dark text-4xl mt-8 mb-12">
                <strong>Wie darf ich dir weiterhelfen?</strong>
              </h3>
              <p className="ct-text-block color-paragraph font-normal text-base mb-12">
                Als dein persönlicher Ernährungsberater helfe ich dir weiter, sodass du dein
                <br />
                persönliches Ziel erreichst. Hierfür ist es zunächst wichtig zu wissen,
                <br />
                welches Ziel du verfolgst und wie ich dich dabei unterstützen kannst.
                <br />
                <br />
                Verrate mir dein Ziel und deine Bedürfnisse unverbindlich über das <br />Kontaktformular.
              </p>
            </div>
            <div className='right-side'>
              <LeadsSurveyForm isCoachSection coach={coachProfile} />
            </div>
          </Styles.Wrapper>
        </div>
      </div>
    </Styles.SectionContainer>
  )
}

export default CoachInitialSection
