import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Rate } from "antd";
import {
  Card,
  CouchItem,
  CouchStats,
  CouchContent,
  Actions,
  CouchProfile,
  IconActions,
} from "./CouchInfo.style";
import DefaultUserProfile from "assets/outer-pages/png/default.png";
import IconWeightLose from "assets/images/Icon-weightlose.svg";
import InstagramIcon from "assets/outer-pages/svg/Instagram-Icon.svg";
import MailIcon from "assets/outer-pages/svg/Mail-Icon.svg";
import ShareIcon from "assets/outer-pages/svg/Share-Icon.svg";
import FemaleIcon from 'assets/images/Icon-female.svg'
import MaleIcon from 'assets/images/male-icon.svg'
import ReviewStars from 'assets/images/review-stars.svg'

function CouchInfo ({ coach, baseUrl, onLeadModal }) {
  const navigate = useNavigate()
  const imgUrl = coach?.profilePic
    ? `${baseUrl}${coach?.profilePic}`
    : DefaultUserProfile
  
  return (
    <>
      <span className="ct-link-text color-paragraph-alt back-link mt-8" onClick={() => navigate(`/`)}>
        {`<< zurück zu den Coaches`}
      </span>
      <Card>
        <CouchProfile>
          <div className="d-flex flex-column align-items-center">
            <img src={imgUrl} alt="user" />
            <IconActions className="d-flex align-items-center">
              <a target="_blank" rel="noreferrer" href={coach.instagram_url}>
                <img src={InstagramIcon} alt="user" />
              </a>
              <a href={`mailto:${coach.email}`}>
                <img src={MailIcon} alt="user" />
              </a>
              <a target="_blank" rel="noreferrer" href={"#"}>
                <img src={ShareIcon} alt="user" />
              </a>
            </IconActions>
          </div>
        </CouchProfile>
        <CouchContent>
          <div>
            <div className="coach-info">
              <div className="name-reviews">
                <div>
                  <span className="text-3xl font-bold">{coach?.prename} {coach?.surname}</span>
                </div>
                <div>
                  <span className="gender">
                    <img src={coach.sex === 'female' ? FemaleIcon : MaleIcon} alt="" />
                  </span>
                  <small>{moment().diff(coach.birthday, "years")} Jahre</small>
                </div>
                <CoachFeedback feedback={coach?.feedback} />
              </div>
              <div className="coach-category">
              <CouchItem className="d-flex align-items-center">
                <div className="coach-icon d-flex align-items-center justify-content-center">
                  <img src={IconWeightLose} alt="weight loss" />
                </div>
                <div>
                  <small>Coach für</small>
                  <div>
                    <strong>{coach?.subtitle}</strong>
                  </div>
                </div>
              </CouchItem>
              </div>
            </div>
            <div className="coach-details">
            <div>
              <CouchStats className="d-grid">
                <div className="d-flex flex-column">
                  <small>Spezialgebiet</small>
                  <p className="mb-0">{coach?.step1}</p>
                </div>
                <div className="d-flex flex-column">
                  <small>Coach seit</small>
                  <p className="mb-0">{coach?.step2}</p>
                </div>
                <div className="d-flex flex-column">
                  <small>Erfahrung</small>
                  <p className="mb-0">{coach?.step3} Jahre</p>
                </div>
                <div className="d-flex flex-column">
                  <small>Praxiserfahrung</small>
                  <p className="mb-0">{coach?.step4}</p>
                </div>
              </CouchStats>
            </div>
            </div>
            <CoachQualifications qualifications={coach?.qualifications} />
          </div>
          <div className="coach-cta">
            <button className="ct-link-text foodiary-green-button text-decoration-none" onClick={() => onLeadModal()}>
              Erstgespräch vereinbaren
            </button>
          </div>
        </CouchContent>
      </Card>
    </>
  )
}

function CoachFeedback ({ feedback }) {
  if (!feedback || !feedback.length) return null
  const totalFeedback = feedback.length
  const averageRating = parseFloat(
    feedback.reduce((acc, curr) => acc + curr.rating, 0) / totalFeedback
  ).toFixed(2)

  return (
    <div className="reviews">
      <Rate style={{ fontSize: '15px' }} disabled allowHalf defaultValue={averageRating} />
      <span>{totalFeedback} reviews / {totalFeedback} sessions</span>
    </div>
  )
}

function CoachQualifications ({ qualifications }) {
  if (!qualifications || !qualifications.length) return (
    <div className="coach-qualifications">
      <strong>Meine Qualifikationen:</strong>
      <p>Dieser Coach hat noch keine Qualifikationen veröffentlicht.</p>
    </div>
  )

  const list = qualifications.map(qualificationItem => (
    <div>
      <span>Abschluss: {qualificationItem.year}</span>
      <span>Art: {qualificationItem.qualification}</span>
    </div>
  ))

  return (
    <div className="coach-qualifications">
      <strong>Meine Qualifikationen:</strong>
      {list}
    </div>
  )
}

export default CouchInfo;
