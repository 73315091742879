import styled from "styled-components"

const Card = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  justify-content: space-between;
  gap: 5rem;

  > * {
    background: white;
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
  }
`

const CouchProfile = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  padding: 0px;
  border: 1rem solid white;
  background: white;
  position: relative;
  align-self: flex-start;

  img {
    width: 100%;
    height: 400px;
    object-fit: cover;
    border-radius: 20px;
  }

  @media (max-width: 1200px) {
    align-items: center;
    width: 50%;
    align-self: center;
  }
  
  @media (max-width: 600px) {
    width: 100%;
  }
`
const IconActions = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  position: absolute;
  width: 140px;
  left: calc(50% - 70px);
  bottom: 15px;
  border-radius: 20px;
  padding: 1.5rem 2rem;
  background: white;
  margin-top: -10rem;
  gap: 1.7rem;
  justify-content: space-between;

  a {
    width: 3rem;
    height: 3rem;
  }
  img {
    width: 3rem !important;
    height: 3rem !important;
    border-radius: 0 !important;
    object-fit: contain !important;
    border: unset !important;
    cursor: pointer;
    box-shadow: none !important;

    &:nth-child(odd) {
      height: 1.34rem;
    }
  }
`

const CouchContent = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px;

  .coach-cta {
    display: flex;
    justify-content: flex-end;
    padding: 20px;

    button {
      background: none;
      color: #142C1F;
      border: 1px solid #D0D5DD;
    }
    
    @media (max-width: 600px) {
      justify-content: center;
      button {
        width: 100%;
      }
    }
  }

  .coach-qualifications {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > div {
      display: flex;
      padding: 5px 0px;

      > span {
        margin-right: 10px;
        position: relative;
      }
      
      span:nth-child(2) {
        padding-left: 10px;

        &:before {
          content: "";
          width: 2px;
          height: 10px;
          top: calc(50% - 5px);
          position: absolute;
          left: 0;
          background: #2ecf96;
        }
      }
    }
  }

  .coach-details {
    > div:nth-child(1) {
      border-top: 1px solid #E2E4E9;
      border-bottom: 1px solid #E2E4E9;
      padding: 15px 20px;
    }
  }

  .coach-info {
    display: flex;
    justify-content: space-between;
    padding: 20px;

    .reviews {
      display: flex;
      align-items: center;
      margin-top: 10px;

      @media (max-width: 600px) {
        flex-direction: column;
        align-items: flex-start;
      }

      > * {
        margin-right: 10px;
      }
    }
    
    .gender {
      margin-right: 10px;
      img {
        width: 20px;
      }
    }

    .name-reviews {
      div:nth-child(2) {
        display: flex;
        align-items: center;
      }

      h2 {
        font-size: 3rem;
      }

      small {
        color: #2ecf96;
        font-size: 2rem;
      }
    }
  }
`

const CouchStats = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  grid-template-columns: 1fr 1fr 1fr 1fr;
  background: white;
  border-radius: 8px;

  div {
    /* border-right: 1px solid #eeeeee; */
    justify-content: center;
    align-items: self-start;
    padding: 0px 20px;
    margin-top: 5px;
    margin-bottom: 5px;
    position: relative;

    &:before {
      content: "";
      width: 2px;
      height: 100%;
      top: 0;
      position: absolute;
      left: 0;
      background: #2ecf96;
    }

    small {
      /* color: #a5b5af; */
      color: #44554c;
      font-size: 1.5rem;
      line-height: 1.5rem;
      margin-top: 0;
    }

    p {
      margin-top: 1.4rem;
      color: #44554c;
      font-size: 1.8rem;
      font-weight: 500;
      word-break: break-word;
      /* text-align: center; */
    }

    &:last-child {
      border-right: none;
    }
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;

    div {
      &:first-child,
      &:nth-child(2) {
        /* border-bottom: 1px solid #eeeeee; */
      }

      &:nth-child(2) {
        border-right: none;
      }
    }
  }
`

const CouchItem = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  .coach-icon {
    width: 8rem;
    margin-right: 5px;
  }

  img {
    width: 100%;
    object-fit: contain;
  }

  div {
    small {
      font-size: 2rem;
      color: #A5B5AF;
    }

    p {
      color: #142c1f;
      font-size: 2.2rem;
      font-weight: 500;
      margin-top: 0;
    }
  }
`

const Actions = styled.div.attrs({
  className: `${(props) => props.className}`,
})`
  a,
  button {
    font-size: 16px;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }
`

export {
  Card,
  CouchStats,
  CouchContent,
  CouchItem,
  Actions,
  CouchProfile,
  IconActions,
}
